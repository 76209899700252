import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import { MenuBar } from './MenuBar';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { TextMotionDiv } from './TextMotionDiv';
import { GalleryItem } from './GalleryItem';
import { parseStrapiRichText } from './App';

export function CustomPage(props) {
    let params = useParams();    
    return (
        <CustomPageComponent pageId={params.id} {...props}/>
    )
}

class CustomPageComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            galleryItems: [],
            galleryItemsLoaded: 0,
            noItems: false,            
        };
    }
    componentDidMount() {
        if (this.props.pageId) {
            axios.get('https://cms.gesad.uber.space/seites/' + this.props.pageId)
            .then(function(response) {                                                          
                this.setState({
                    title: response.data.Titel,
                    subTitle: response.data.Untertitel,
                    content: response.data.Inhalt ? parseStrapiRichText(response.data.Inhalt) : '',
                });
            }.bind(this))
            .catch(function(error) {
                console.log(error);
            }.bind(this));
        }
    }
    render() {
        return(
            <div>
                <Container>
                    <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>                    
                    <Row>
                        <Col>
                            <div style={styles.textBox}>
                                <TextMotionDiv visible={this.state.title} preset='fadeInUpwards' whenScrolledIntoViewport>
                                    <div style={styles.header}>
                                        {this.state.title}
                                    </div>
                                    {this.state.subTitle && this.state.subTitle !== '' &&
                                    <div style={styles.subHeader}>
                                        {this.state.subTitle}
                                    </div>
                                    }
                                </TextMotionDiv>
                            </div>                            
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={11} lg={10}>
                            <TextMotionDiv style={styles.content}
                            visible={this.state.content} preset='fadeInUpwards'>
                                <div dangerouslySetInnerHTML={{__html: this.state.content}}/>
                            </TextMotionDiv>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

CustomPageComponent = Radium(CustomPageComponent);

var styles = {
    textBox: {
        textAlign: 'center',
    },
    header: {
        fontFamily: '"Cormorant Garamond", serif',
        marginTop: '3rem',
        fontSize: '5.0rem',
        fontWeight: '600',
        '@media (max-width: 767.98px)': {
            marginTop: '2rem',
            fontSize: '3.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '2.6rem',
            fontSize: '4.0rem',
        },
    },
    subHeader: {
        marginTop: '-1rem',
        marginLeft: '1.0rem',
        marginRight: '1.0rem',
        fontSize: '1.3rem',
        '@media (max-width: 767.98px)': {
            fontSize: '1.0rem',
            marginTop: '-0.4rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
            marginTop: '-0.6rem',
        },
    },
    content: {
        marginTop: '4.5rem',
        marginBottom: '2.25rem',
        marginLeft: '0.5rem',
        fontSize: '1.3rem',
        '@media (max-width: 767.98px)': {
            marginTop: '2.6rem',
            marginBottom: '1.3rem',
            fontSize: '1.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '3.3rem',
            marginBottom: '1.7rem',
            fontSize: '1.2rem',
        },     
    },
};