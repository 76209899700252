import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import { TextMotionDiv } from './TextMotionDiv';

import violin from './svg/violin_02.svg';
import menuOpen from './svg/menu_open.svg';

export class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuData: [],
            socialMedia: [],
        };
    }
    componentDidMount() {
        axios.get('https://cms.gesad.uber.space/seites')
        .then(function(response) {                      
            let menuData = [];
            for(const page of response.data) {
                menuData.push({
                    title: page.Titel,
                    content: page.Inhalt,
                    destination: '/pages/' + page.id,
                });
            }
            this.setState({
                menuData: menuData
            });
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));

        axios.get('https://cms.gesad.uber.space/social-medias')
        .then(function(response) {                                  
            let socialMedia = [];
            for(const item of response.data) {
                socialMedia.push({
                    link: item.Link.includes('https://') ? item.Link 
                    : 'https://' + item.Link,  
                    imageURL: 'https://cms.gesad.uber.space' + item.Logo.url,
                });
            }
            this.setState({
                socialMedia: socialMedia,
            });            
            console.log(socialMedia);
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    }
    render() {
        return(
            <Row className='pt-3 pt-md-4'>
                <Col xs={10} lg={4}>
                    <TextMotionDiv visible={true} preset='fadeIn' whenScrolledIntoViewport>
                    <div style={styles.header}>                        
                        <Row className='mt-2'>
                            <Col xs={1}>         
                                <NavLink key={'home'} to='/' style={styles.link}>
                                    <img style={styles.logo} src={violin}/>
                                </NavLink>
                            </Col>
                            <Col>                                
                                    <div style={styles.logoTextBox}>
                                        <div style={styles.name}>
                                            <NavLink key={'home'} to='/' style={styles.link}>
                                                Gesa Daniel
                                            </NavLink>
                                        </div>
                                        <div style={styles.description}>
                                            <NavLink key={'home'} to='/' style={styles.link}>
                                                Geige, Gitarre und Gesang
                                            </NavLink>
                                        </div>
                                    </div>                                
                            </Col>
                        </Row>                                           
                    </div>
                    </TextMotionDiv>
                </Col>
                <Col className='d-none d-lg-block'>
                    <TextMotionDiv visible={true} preset='fadeIn' whenScrolledIntoViewport>
                    <div style={styles.menuBar}>
                        <div style={{ display: 'inline-block'}}>
                            <NavLink key={'tourdates'} to='/tourdates' 
                            style={styles.link}>
                                    Meine Auftritte
                            </NavLink>
                        </div>
                        <div style={{display: 'inline-block', marginLeft: '2.0rem'}}>
                            <a style={styles.link} target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UC17Z-OLZzJWsto59lCDRGbQ'>
                                Musikvideos
                            </a>
                        </div>
                        <div style={{ display: 'inline-block', marginLeft: '2rem'}}>
                            <NavLink key={'gallery'} to='/gallery'
                            style={styles.link}>
                                    Galerie
                            </NavLink>
                        </div>
                        {this.state.menuData.map(function(page, index){
                            return(
                                <div style={{ display: 'inline-block', marginLeft: '2rem'}}>
                                    <NavLink key={'page ' + page.destination} to={page.destination}
                                    style={styles.link}>
                                            {page.title}
                                    </NavLink>
                                </div>
                            );
                        }.bind(this))
                        }
                        <div className='text-right' style={{display: 'inline-block', marginLeft: '2rem'}}>
                        {this.state.socialMedia.map(function(media, index) {
                            return(
                                <div style={{display: 'inline-block', marginRight: '0.5rem'}}>
                                    <a href={media.link} target="_blank" rel="noopener noreferrer">
                                        <img className='img-fluid' style={{width: '2rem', marginBottom: '0.2rem'}} src={media.imageURL}/>
                                    </a>
                                </div>                                    

                            );
                        }.bind(this))
                        }
                        </div>
                    </div>
                    </TextMotionDiv>
                </Col>
                <Col className='d-block d-lg-none' style={{textAlign: 'right'}}>
                    <img style={styles.menuOpen} src={menuOpen} onClick={() => this.props.togglePopupMenu()}/>
                </Col>
            </Row>
        );
    }
}

MenuBar = Radium(MenuBar);

var styles = {
    link: {
        color: '#000',
        textDecoration: 'none',
    },
    logo: {                
        '@media (max-width: 767.98px)': {
            marginTop: '0.1rem',
            width: '2.0rem',
        },
        marginTop: '0.2rem',
        width: '2.5rem',
    },
    header: {                                    
        fontFamily: '"Cormorant Garamond", serif',
        overflowX: 'visible',                                              
        textShadow: '0.02rem 0.02rem 0.04rem #aaa',
        '@media (max-width: 767.98px)': {
            marginLeft: '0.5rem',    
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginLeft: '-0.5rem',    
        },
        marginLeft: '0.0rem',
    },
    logoTextBox: {
        paddingTop: '0.5rem',
        '@media (max-width: 767.98px)': {
            marginLeft: '1.0rem',
            paddingTop: '0.65rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginLeft: '0.0rem',
            paddingTop: '1.0rem',
        },
        marginLeft: '1.8rem',
    },
    name: {
        fontWeight: '700',
        fontSize: '3.25rem',
        lineHeight: '2.0rem',
        letterSpacing: '-0.05rem',
        paddingBottom: '0.3rem',
        '@media (max-width: 767.98px)': {
            fontWeight: '700',
            fontSize: '2.0rem',
            lineHeight: '1.4rem',
            letterSpacing: '-0.05rem',
            paddingBottom: '0.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontWeight: '700',
            fontSize: '2.6rem',
            lineHeight: '1.4rem',
            letterSpacing: '-0.05rem',                        
            paddingBottom: '-0.5rem',
        },
    },
    description: {
        fontWeight: '400',
        fontSize: '1.4rem', 
        marginLeft: '0.2rem',
        '@media (max-width: 767.98px)': {
            fontSize: '0.85rem', 
            marginLeft: '0.2rem',   
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.1rem', 
            marginLeft: '0.2rem',   
        },
    },   
    menuBar: {
        marginTop: '2.2rem',                                    
        textAlign: 'right',
        fontFamily: '"Inter", sans-serif',
        fontWeight: '600',  
        fontSize: '1.2rem',  
        textShadow: '0.02rem 0.02rem 0.04rem #bbb',
    }, 
    menuOpen: { 
        cursor: 'pointer',       
        '@media (max-width: 767.98px)': {
            width: '2rem',
            marginTop: '1.2rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            width: '2.7rem',
            marginTop: '1.5rem',
        },
        width: '2rem',
    },
};