import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import { MenuBar } from './MenuBar';
import axios from 'axios';

import { TextMotionDiv } from './TextMotionDiv';
import { parseStrapiDatetime } from './App.js';

import note from './svg/notes.svg';

export class Tourdates extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tourdates: [],
            oldTourdates: [],
            noTourdates: false
        };
    }
    componentDidMount() {
        axios.get('https://cms.gesad.uber.space/auftritts/')
        .then(function(response) {                                          
            let tourdates = [];
            let oldTourdates = [];
            let d = new Date();
            let day = d.getDate();        
            let month = d.getMonth() + 1;
            let hours = d.getHours();
            if (hours >= 2) {
                hours -= 2;
            }
            let nowTimestamp = d.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + ' ' + (hours < 10 ? '0' : '') + hours + ':00';
            for (let event of response.data) {
                let datetime = '';
                let timestamp = '';
                if (event.Zeitpunkt) {
                    let parsedDatetime = parseStrapiDatetime(event.Zeitpunkt);                    
                    datetime = parsedDatetime[0] + ' / ' + parsedDatetime[1];
                    timestamp = parsedDatetime[2];                                    
                }                
                if (timestamp > nowTimestamp) {
                    tourdates.push({
                        Titel: event.Titel,
                        Beschreibung: event.Beschreibung,
                        Zeitpunkt: datetime,
                        timestamp: timestamp,
                        Ort: event.Ort,
                    });
                } else {
                    oldTourdates.push({
                        Titel: event.Titel,
                        Beschreibung: event.Beschreibung,
                        Zeitpunkt: datetime,
                        timestamp: timestamp,
                        Ort: event.Ort,
                    });
                }
            }      
            tourdates.sort((event0, event1) => event0.timestamp > event1.timestamp ? 1 : -1);
            oldTourdates.sort((event0, event1) => event0.timestamp < event1.timestamp ? 1 : -1);
            this.setState({
                tourdates: tourdates,
                oldTourdates: oldTourdates,
                noTourdates: (tourdates.length == 0)
            });
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    }
    render() {
        return(
            <div>
                <Container>
                    <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>                    
                    <Row>
                        <Col>
                            <div style={styles.textBox}>
                                <TextMotionDiv visible={true} preset='fadeInUpwards' whenScrolledIntoViewport>
                                    <div style={styles.header}>
                                        Tourdaten
                                    </div>
                                    <div style={styles.subHeader}>
                                        Wo ich in nächster Zeit auftrete.
                                    </div>
                                </TextMotionDiv>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={styles.list}>
                                {                                
                                    this.state.tourdates.map(function(event, index) {                                        
                                        return (
                                            <>
                                                <TextMotionDiv visible={true} preset='fadeInUpwards' whenScrolledIntoViewport>
                                                    <div style={styles.eventTitle}>{event.Titel}</div>
                                                    <div style={styles.eventInfo}>
                                                        {event.Ort}
                                                        {event.Ort ? ' am ' : ''}
                                                        {event.Zeitpunkt}
                                                    </div>
                                                    <div style={styles.eventDescription}>{event.Beschreibung}</div>
                                                </TextMotionDiv>
                                                {(index < this.state.tourdates.length -1) && 
                                                    <TextMotionDiv visible={true} preset='fadeInUpwards' whenScrolledIntoViewport>
                                                        <div style={styles.separator}></div>
                                                    </TextMotionDiv>
                                                }
                                            </>
                                        )
                                    }.bind(this))                                
                                }    
                                {
                                    this.state.noTourdates &&
                                    <TextMotionDiv visible={true} preset='fadeInUpwards' whenScrolledIntoViewport>
                                        <div style={[styles.eventPlaceholder]}>
                                            Schau bald wieder vorbei.
                                        </div>
                                    </TextMotionDiv>
                                }
                            </div>                        
                        </Col>
                    </Row>
                    <Row className='mt-5 mb-n2'>
                        
                    </Row>
                    <Row className='mb-4'>
                        <Col>
                            <div style={[styles.list, styles.oldTourdate]}>
                                {                                
                                    this.state.oldTourdates.map(function(event, index) {
                                        return (
                                            <>
                                                <TextMotionDiv visible={true} preset='fadeInUpwards' whenScrolledIntoViewport>
                                                    <div style={styles.eventTitle}>{event.Titel}</div>
                                                    <div style={styles.eventInfo}>
                                                        {event.Ort}
                                                        {event.Ort ? ' am ' : ''}
                                                        {event.Zeitpunkt}
                                                    </div>
                                                    <div style={styles.eventDescription}>{event.Beschreibung}</div>
                                                </TextMotionDiv>
                                                {(index < this.state.oldTourdates.length -1) && 
                                                    <TextMotionDiv visible={true} preset='fadeInUpwards' whenScrolledIntoViewport>
                                                        <div style={styles.separator}></div>
                                                    </TextMotionDiv>
                                                }
                                            </>
                                        )
                                    }.bind(this))                                
                                }
                            </div>                        
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Tourdates = Radium(Tourdates);

var styles = {
    textBox: {
        textAlign: 'center',
    },
    header: {
        fontFamily: '"Cormorant Garamond", serif',
        marginTop: '3rem',
        fontSize: '5.0rem',
        fontWeight: '600',
        '@media (max-width: 767.98px)': {
            marginTop: '2rem',
            fontSize: '3.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '2.6rem',
            fontSize: '4.0rem',
        },
    },
    subHeader: {
        marginTop: '-1rem',
        marginLeft: '1.0rem',
        marginRight: '1.0rem',
        fontSize: '1.3rem',
        '@media (max-width: 767.98px)': {
            fontSize: '1.0rem',
            marginTop: '-0.4rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
            marginTop: '-0.6rem',
        },
    },
    list: {
        marginTop: '4rem',
        marginLeft: '0.5rem',
        '@media (max-width: 767.98px)': {
            marginTop: '3.3rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '3.7rem',
        },
    },
    oldTourdate: {
        color: '#aaa',
    },
    eventTitle: {        
        fontWeight: 600,
        fontSize: '1.9rem',
        '@media (max-width: 767.98px)': {
            fontSize: '1.3rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.7rem',
        },
    },
    eventInfo: {
        fontSize: '1.3rem',
        '@media (max-width: 767.98px)': {
            fontSize: '1.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
        },
    },
    eventDescription: {
        fontSize: '1.3rem',
        '@media (max-width: 767.98px)': {
            fontSize: '1.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
        },
    },
    eventPlaceholder: {        
        textAlign: 'center',
        fontSize: '1.3rem',
        '@media (max-width: 767.98px)': {
            fontSize: '1.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
        },
    },
    separator: {
        borderBottom: '1px solid #eee',
        marginTop: '1.5rem',
        marginBottom: '1.2rem',
    }
};