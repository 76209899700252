import React from 'react';
import Radium from 'radium';
import { Row, Col } from 'react-bootstrap';

import { TextMotionDiv } from './TextMotionDiv';

export class GalleryItem extends React.Component {
    constructor(props) {
        super(props);
        this.backgroundImage = new Image();
        this.backgroundImage.src = props.url;
        this.backgroundImage.onload = function() {                    
            this.setState({backgroundImageLoaded: true});
            if (this.props.onImageLoad) {
                this.props.onImageLoad(this.props.index);
            }
        }.bind(this);
    }
    render() {
        return(            
            <Row>
                <Col xs={12} lg={6} className={'order-0 ' + ((this.props.index % 2) === 1 ? 'order-lg-1' : 'order-lg-0' )}>
                    <TextMotionDiv visible={this.state.backgroundImageLoaded}
                    preset='fadeInUpwards'>
                        <div style={((this.props.index % 2) === 0) ? [styles.pictureBox, styles.paddingRight] : [styles.pictureBox, styles.paddingLeft]}>
                            <img className='img-fluid' src={this.backgroundImage.src}/>
                        </div>
                    </TextMotionDiv>
                </Col>
                <Col xs={12} lg={6} className={'order-1 ' + ((this.props.index % 2) === 1 ? 'order-lg-0' : 'order-lg-1' )}>
                    <TextMotionDiv visible={this.state.backgroundImageLoaded}
                    preset='fadeInUpwards'>
                        <div style={((this.props.index % 2) === 0) ? [styles.textBox, styles.paddingLeft] : [styles.textBox, styles.paddingRight]}>
                            <div style={styles.title}>
                                {this.props.title}
                            </div>
                            <div style={styles.text} dangerouslySetInnerHTML={{__html: this.props.text}}/>                            
                        </div>
                    </TextMotionDiv>
                </Col>
            </Row>            
        );
    }
}

GalleryItem = Radium(GalleryItem);

var styles = {
    paddingLeft: {
        paddingLeft: '2rem',
        '@media (max-width: 991.98px)': {
            paddingLeft: '0rem',
        },
    },
    paddingRight: {
        paddingRight: '2rem',
        '@media (max-width: 991.98px)': {
            paddingRight: '0rem',
        },
    },
    title: {        
        fontWeight: 600,
        fontSize: '1.9rem',
        marginBottom: '2rem',        
        '@media (max-width: 767.98px)': {
            fontSize: '1.3rem',
            marginBottom: '0.5rem',
            marginTop: '1.5rem',
            paddingRight: '1.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.7rem',
            marginBottom: '0.9rem',
            marginTop: '2.3rem',
            paddingRight: '1.5rem',
        },
    },
    text: {
        fontSize: '1.3rem',
        '@media (max-width: 767.98px)': {
            fontSize: '1.0rem',
            paddingRight: '1.0rem',            
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
            paddingRight: '1.5rem',
            marginBottom: '0.9rem',
        },
    },
    separator: {
        borderBottom: '1px solid #eee',
        marginBottom: '4rem',        
        '@media (max-width: 767.98px)': {
            marginBottom: '1.9rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginBottom: '3.0rem',
        },
    }
};