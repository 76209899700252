import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import Div100vh from 'react-div-100vh';

import { TextMotionDiv } from './TextMotionDiv';
import { MenuBar } from './MenuBar';

import backgroundVideo from './videos/gesa_01.mp4';


export class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        let quotes = [
            'Bin frei vom Wind getragen, <br> in meinem Traum kannst du mich lieben.',
            'Der Reissverschluss ist offen, <br> das Publikum wirkt unbetroffen.',
            'Der Fehler liegt im System, <br> das sagt wer ist schön.',
            'In meinem Traum kann ich dich lieben, <br> so wie du bist.',
            'Ist das alles was von dir bleibt? <br> Ein Bild, Erinnerung und ein Gefühl?'
        ];
        this.state = {
            quote: quotes[Math.floor(Math.random() * quotes.length)]
        }
    }
    render() {
        return(    
            <div className=''>                                                     
                <div style={styles.parentElementToVideo}>
                    <video id='vid' style={styles.video}
                    disablePictureInPicture loop muted autoPlay playsInline>
                        <source src={backgroundVideo} type='video/mp4'></source>
                    </video>
                </div>                                                      
                <Container className=''> 
                        <Div100vh>
                            <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>
                            <div className='fixed-bottom' style={{zIndex: -1000}}>
                                <Container className='px-0 px-md-3 px-lg-4 px-5'>
                                    <Row>
                                        <Col xs={11} md={11} lg={8}>
                                            <TextMotionDiv visible={true} preset='fadeInUpwards' whenScrolledIntoViewport>
                                                <div style={styles.quote}>
                                                    &bdquo;<span style={{marginLeft: '0.2rem'}}></span>
                                                    <span dangerouslySetInnerHTML={{__html: this.state.quote}}/> 
                                                    &ldquo;
                                                </div>
                                            </TextMotionDiv>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>                      
                        </Div100vh>                        
                </Container>                             
            </div>
        );
    }
}

LandingPage = Radium(LandingPage);

var styles = {
    quote: {
        zIndex: -1000,
        fontFamily: '"Cormorant Garamond", serif',
        fontSize: '2.5rem',
        fontWeight: '600',
        fontStyle: 'italic', 
        lineHeight: '2.7rem',
        letterSpacing: '-0.075rem',
        marginBottom: '4rem',
        marginLeft: '1.0rem',
        textShadow: '0.02rem 0.02rem 0.04rem #bbb',
        '@media (max-width: 767.98px)': {
            lineHeight: '1.9rem',
            letterSpacing: '-0.075rem',
            fontSize: '1.6rem',
            marginLeft: '-1.4rem',
            marginBottom: '1.5rem',            
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            lineHeight: '1.9rem',
            letterSpacing: '-0.075rem',
            fontSize: '2.0rem',
            marginLeft: '-1.4rem',
            marginBottom: '3.5rem',            
        },
    },
    parentElementToVideo: {
        //marginTop: '4rem',
        userSelect: 'none',
        pointerEvents: 'none',
        zIndex: -1000,
        position: 'fixed',
        overflow: 'hidden',
        //width: '90%',
        width: '100%',
        //marginLeft: '5%',
        height: '100vh',                
    },
    video: {        
        userSelect: 'none',
        pointerEvents: 'none',
        zIndex: -1,
        height: '100%',
        width: '177.777vh',
        minWidth: '100%',
        minHeight: '56.25vw',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',        
    },
};