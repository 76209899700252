import React from 'react';
import Radium from 'radium';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';

import { LandingPage } from './LandingPage';
import { PopupMenu } from './PopupMenu';
import { Tourdates } from './Tourdates';
import { Gallery } from './Gallery';
import { CustomPage } from './CustomPage';

import './App.css';

export function parseStrapiDatetime(datetime) {    
    let date = '';
    let time = '';
    let timestamp = '';
    let datestamp = '';
    if (datetime) {         
        let d = new Date(datetime);
        time = (d.getHours() < 10 ? '0' : '') + d.getHours();
        if (d.getMinutes() !== 0) time += ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        time += ' Uhr';
        date = (d.getDate() < 10 ? '0' : '') + d.getDate() + '.'
        + (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) + '.'
        + (d.getFullYear() + '').substr(2, 2);
        timestamp = 
        d.getFullYear()
        + '-' + (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) 
        + '-' + (d.getDate() < 10 ? '0' : '') + d.getDate()
        + ' ' + (d.getHours() < 10 ? '0' : '') + d.getHours()
        + ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes(); 
        datestamp = 
        d.getFullYear()
        + '-' + (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) 
        + '-' + (d.getDate() < 10 ? '0' : '') + d.getDate();
    
    }    
    return [date, time, timestamp, datestamp];
}

export function parseStrapiRichText(richText) {
    let parsedRichText = '';
    // Line breaks
    parsedRichText = richText.replace(/(?:\r\n|\r|\n)/g, '<br>');
    // Media downloads
    let regExp = /(\!\[.*?\]\(.*?\))/;                
    let match = null;
    let isDownload = false;
    do {
        match = regExp.exec(parsedRichText);
        if (match) {
            match = match[0];
            isDownload = true;
            
            let urlStartIndex = match.indexOf('(');                
            let url = match.substring(urlStartIndex + 1, match.length - 1);                                
            if (url.indexOf('.jpg') >= 0 || url.indexOf('.png') >= 0 || url.indexOf('.jpeg') >= 0
            || url.indexOf('.gif') >= 0) {
                isDownload = false;
            }
            
            let text = match.substring(2, urlStartIndex - 1);
            if (text.indexOf('Download') === 0) {
                text = text.replace('Download', '').trim();
                isDownload = true;
            }            

            if (isDownload) {
                parsedRichText = parsedRichText.replace(match, '<a class="link" target="_blank" rel="noopener noreferrer" href="' + url + '">'+ text +'</a>');
            }
            else {                
                parsedRichText = parsedRichText.replace(match, '<img class="img-fluid" src="' + url + '" alt="' + text + '"\>')                
            }
        }
    } while (match);
    // Hyperlinks        
    regExp = /(\[.*?\]\(.*?\))/;        
    do {
        match = regExp.exec(parsedRichText);
        if (match) {  
            match = match[0];              
            let urlStartIndex = match.indexOf('(');                
            let text = match.substring(1, urlStartIndex - 1);
            let url = match.substring(urlStartIndex + 1, match.length - 1);
            parsedRichText = parsedRichText.replace(match, '<a class="link" target="_blank" rel="noopener noreferrer" href="' + url + '">'+ text +'</a>');            
        }        
    } while (match);
    return parsedRichText;
}

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
        };
    }
    togglePopupMenu() {    
        this.setState({showMenu: !this.state.showMenu});
    }
    render() {
        return (        
            <div style={{
                color: '#000',
                fontFamily: '"Inter", sans-serif',
            }}>
                <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
                integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                crossorigin="anonymous"
                />           
                <Router>                    
                    <PopupMenu showMenu={this.state.showMenu} togglePopupMenu={this.togglePopupMenu.bind(this)}/>   
                    <Routes>
                        <Route exact path='/' element={
                            <LandingPage togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        }/>
                        <Route exact path='/tourdates' element={
                            <Tourdates togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        }/>
                        <Route exact path='/gallery' element={
                            <Gallery togglePopupMenu={this.togglePopupMenu.bind(this)}/>
                        }/>
                        <Route path='/pages/:id' element={
                            <CustomPage togglePopupMenu={this.togglePopupMenu.bind(this)}/>                                
                        }/>
                    </Routes>                        
                </Router>
            </div>
        );
    }
}

App = Radium(App);
