import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import { MenuBar } from './MenuBar';
import axios from 'axios';

import { TextMotionDiv } from './TextMotionDiv';
import { GalleryItem } from './GalleryItem';
import { parseStrapiRichText } from './App';

export class Gallery extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            galleryItems: [],
            galleryItemsLoaded: 0,
            noItems: false,            
        };
    }
    componentDidMount() {
        axios.get('https://cms.gesad.uber.space/erinnerungsbilds/')
        .then(function(response) {                                          
            let galleryItems = [];
            for (let item of response.data) {                 
                galleryItems.push({
                    Titel: item.Titel,
                    Bild: (item.Bild ? 'https://cms.gesad.uber.space' + item.Bild.url : ''),
                    Text: item.Text ? parseStrapiRichText(item.Text) : '',
                });
            }
            this.setState({
                galleryItems: galleryItems.reverse()
            });
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    }
    render() {
        return(
            <div>
                <Container>
                    <MenuBar togglePopupMenu={this.props.togglePopupMenu}/>                    
                    <Row>
                        <Col>
                            <div style={styles.textBox}>
                                <TextMotionDiv visible={true} preset='fadeInUpwards' whenScrolledIntoViewport>
                                    <div style={styles.header}>
                                        Galerie
                                    </div>
                                    <div style={styles.subHeader}>
                                        Schöne Bilder mit schönen Erinnerungen.
                                    </div>
                                </TextMotionDiv>
                            </div>
                        </Col>
                    </Row>    
                    <div style={styles.galleryList}>
                        {this.state.galleryItems && this.state.galleryItems.length > 0 &&
                        this.state.galleryItems.slice(0, this.state.galleryItemsLoaded + 1).map(function(item, index) {
                            return (
                                <div>
                                    <div style={styles.galleryItem}>
                                        <GalleryItem index={index} 
                                        onImageLoad={() => this.setState({
                                            galleryItemsLoaded: this.state.galleryItemsLoaded + 1,
                                        })}
                                        borderBottom={index < this.state.galleryItems.length - 1} 
                                        title={item.Titel} text={item.Text} url={item.Bild}/>
                                    </div>
                                    <TextMotionDiv visible={index < this.state.galleryItemsLoaded 
                                    && index < this.state.galleryItems.length - 1}
                                    preset='fadeInUpwards'
                                    style={styles.separator}>   
                                    </TextMotionDiv>
                                </div>
                            )
                        }.bind(this))
                        }
                    </div>
                </Container>
            </div>
        );
    }
}

Gallery = Radium(Gallery);

var styles = {
    textBox: {
        textAlign: 'center',
    },
    header: {
        fontFamily: '"Cormorant Garamond", serif',
        marginTop: '3rem',
        fontSize: '5.0rem',
        fontWeight: '600',
        '@media (max-width: 767.98px)': {
            marginTop: '2rem',
            fontSize: '3.0rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '2.6rem',
            fontSize: '4.0rem',
        },
    },
    subHeader: {
        marginTop: '-1rem',
        marginLeft: '1.0rem',
        marginRight: '1.0rem',
        fontSize: '1.3rem',
        '@media (max-width: 767.98px)': {
            fontSize: '1.0rem',
            marginTop: '-0.4rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.2rem',
            marginTop: '-0.6rem',
        },
    },
    galleryList: {
        marginTop: '6rem',
        marginLeft: '0.5rem',
        '@media (max-width: 767.98px)': {
            marginTop: '3.3rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginTop: '3.7rem',
        },
    },
    galleryItem: {
        marginBottom: '4rem',        
        '@media (max-width: 767.98px)': {
            marginBottom: '1.5rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginBottom: '1.2rem',
        },
    },    
    separator: {
        borderBottom: '1px solid #eee',
        marginBottom: '4rem',        
        '@media (max-width: 767.98px)': {
            marginBottom: '1.9rem',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            marginBottom: '3.0rem',
        },
    }
};