import React from 'react';
import Radium from 'radium';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, NavLink, Link } from 'react-router-dom';
import axios from 'axios';

import { TextMotionDiv } from './TextMotionDiv';

import menuClose from './svg/menu_close.svg';
import logoImage from './svg/violin.svg';

export class PopupMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {                        
            fixedMenuData: [
                {
                    title: 'Startseite',
                    destination: '/',
                },
                {
                    title: 'Meine Auftritte',
                    destination: '/tourdates',
                },
                {
                    title: 'Galerie',
                    destination: '/gallery',
                }
            ],
            menuData: [],
            socialMedia: [],     
        };
    }
    componentDidMount() {
        axios.get('https://cms.gesad.uber.space/seites')
        .then(function(response) {                      
            let menuData = [];
            for(const page of response.data) {
                menuData.push({
                    title: page.Titel,
                    content: page.Inhalt,
                    destination: '/pages/' + page.id,
                });
            }
            this.setState({
                menuData: menuData
            });
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));

        axios.get('https://cms.gesad.uber.space/social-medias')
        .then(function(response) {                                  
            let socialMedia = [];
            for(const item of response.data) {
                socialMedia.push({
                    link: item.Link.includes('https://') ? item.Link 
                    : 'https://' + item.Link,  
                    imageURL: 'https://cms.gesad.uber.space' + item.Logo.url,
                });
            }
            this.setState({
                socialMedia: socialMedia,
            });            
            console.log(socialMedia);
        }.bind(this))
        .catch(function(error) {
            console.log(error);
        }.bind(this));
    }
    render() {        
        return (
            <>
            <div className='fixed-top' style={[styles.menu, 
                {  
                    transition: 'transform 0.4s ease',
                    transform: this.props.showMenu ? 'translateX(0vw)' : 'translateX(100vw)',
                    pointerEvents: this.props.showMenu ? 'auto' : 'none'
                }]}>
                <Container fluid>
                    <Row style={{
                        transition: 'opacity 2s ease',                            
                        opacity: this.props.showMenu ? 1.0 : 0.0,
                    }}>
                        <Col>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', color: '#fff'}}>
                            <div style={{flex: '1'}} className='text-left'>
                                <img style={styles.logo} src={logoImage}
                                onClick={() => this.props.togglePopupMenu()}
                                />
                            </div>
                            <div style={{flex: 1, textAlign: 'right'}}
                                onClick={() => this.props.togglePopupMenu()}>
                                <img style={styles.menuButton} src={menuClose}/>
                            </div>
                        </div>
                        </Col>
                    </Row>
                    <Row className='px-2' style={{
                        transition: 'opacity 1.3s ease, margin-left 1.5s ease',
                        marginLeft: this.props.showMenu ? '0rem' : '2rem',
                        opacity: this.props.showMenu ? 1.0 : 0.0,
                    }}>
                        <Col>
                            <div style={styles.menuLinkList} className='mt-5'>                                
                                {this.state.fixedMenuData.map((value, index) =>
                                    <NavLink onClick={function() {
                                            window.scrollTo(0, 0);
                                            this.props.togglePopupMenu();                  
                                        }.bind(this)} exact
                                        key={'menuLink' + index} to={value.destination}
                                        style={styles.menuNavLink}>  
                                        <div key={'menuLinkDiv' + index} className='mb-3 mb-md-3' 
                                        style={styles.menuNavLinkDiv}>
                                            {value.title}
                                        </div>                                      
                                    </NavLink>
                                    
                                )}
                                <div style={styles.menuNavLink}>
                                    <div key={'youtube'} className='mb-3 mb-md-3' style={styles.menuNavLinkDiv}>
                                        <a style={styles.link} target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UC17Z-OLZzJWsto59lCDRGbQ'>
                                            Musikvideos
                                        </a>
                                    </div>
                                </div>
                                {this.state.menuData.map((value, index) =>
                                    <NavLink onClick={function() {
                                            window.scrollTo(0, 0);
                                            this.props.togglePopupMenu();                  
                                        }.bind(this)} exact
                                        key={'menuLink ' + value.destination} to={value.destination}
                                        style={styles.menuNavLink}>  
                                        <div key={'menuLinkDiv ' + value.destination} className='mb-3 mb-md-3' 
                                        style={styles.menuNavLinkDiv}>
                                            {value.title}
                                        </div>                                      
                                    </NavLink>
                                    
                                )}
                                <div className='text-right' style={{display: 'inline-block', marginTop: '2rem'}}>
                                {this.state.socialMedia.map(function(media, index) {
                                    return(
                                        <div style={{display: 'inline-block', marginRight: '0.5rem'}}>
                                            <a href={media.link} target="_blank" rel="noopener noreferrer">
                                                <img className='img-fluid' style={{width: '2rem'}} src={media.imageURL}/>
                                            </a>
                                        </div>                                    

                                    );
                                }.bind(this))
                                }
                                </div>
                            </div>                        
                            
                        </Col>
                    </Row>           
                </Container>     
            </div> 
            <style jsx>
                {`
                .helpLink {
                    color: #fff;                                                
                    transition: border-bottom-color 0.5s ease;
                    border-bottom-width: 0.065rem;
                    border-bottom-style: solid;
                    border-bottom-color: white;
                }
                .helpLink:hover {
                    color: #fff;
                    text-decoration: none;
                    border-bottom-color: transparent;
                }
                `}
            </style>
            </>
        );
    }
}

PopupMenu = Radium(PopupMenu);

var styles = {      
    link: {
        color: '#000',
        textDecoration: 'none',
    },
    menu: {
        width: '100%', 
        height: '100%', 
        backgroundColor: '#fff', //010101
        zIndex: 1000,
    },
    logo: {
        '@media (max-width: 767.98px)': {
            width: '10rem',
            marginLeft: '0.5rem',
            marginTop: '1.4rem'
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            width: '12rem',
            marginLeft: '0.5rem',
            marginTop: '1.3rem'
        },
        '@media (min-width: 992px) and (max-width: 1199.98px)': {
            width: '14rem',
            marginLeft: '0.5rem',
            marginTop: '1.2rem'
        },
        '@media (min-width: 1200px)': {
            width: '15rem',
            marginLeft: '0.5rem',
            marginTop: '1.2rem'
        }
    },
    logoCut: {
        '@media (max-width: 767.98px)': {
            width: '4rem',
            marginLeft: '0rem',
            marginTop: '1rem'
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            width: '4.5rem',
            marginLeft: '0.3rem',
            marginTop: '1.3rem'
        },
        '@media (min-width: 992px) and (max-width: 1199.98px)': {
            width: '5rem',
            marginLeft: '0.2rem',
            marginTop: '1rem'
        },
        '@media (min-width: 1200px)': {
            width: '5rem',
            marginLeft: '0.2rem',
            marginTop: '1rem'
        }
    },
    menuButton: {
        cursor: 'pointer',
        '@media (max-width: 767.98px)': {
            width: '2.5em', 
            height: '2.5em',             
            marginRight: '0rem',
            marginTop: '1.2rem'
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            width: '3em', 
            height: '3em',  
            marginRight: '0rem',
            marginTop: '1rem'
        },
        '@media (min-width: 992px) and (max-width: 1199.98px)': {
            width: '3em', 
            height: '3em',             
            marginRight: '0rem',
            marginTop: '1rem'
        },
        '@media (min-width: 1200px)': {
            width: '3em', 
            height: '3em',             
            marginRight: '0rem',
            marginTop: '1rem'
        }                
    },    
    menuLinkList: {
        color: '#000',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingBottom: '1rem',
        borderBottom: '0.065rem solid white',

    },
    menuNavLink: {
        display: 'flex',
        flex: 1,
        flex: 'column',
        color: '#000',
        fontWeight: 500,
        textDecoration: 'none',        
    },
    menuNavLinkDiv: {
        display: 'inline-block', 
        borderBottomColor: 'transparent',
        borderBottomStyle: 'solid',
        borderBottomWidth: '0.065rem',
        transition: 'border-bottom-color 0.5s ease',
        '@media (max-width: 767.98px)': {
            fontSize: '1.3rem',
            lineHeight: '1.2',
        },
        '@media (min-width: 768px) and (max-width: 991.98px)': {
            fontSize: '1.7rem',
            lineHeight: '1.6',
        },
        ':hover': {
            borderBottomColor: 'white'
        }
    },
    menuLinkWrapper: {        
        display: 'flex',
        flexDirection: 'column',
        flex: 1,                   
    },
    helpText: {
        color: '#fff',
        textDecoration: 'none',
        fontFamily: '"Josefin Sans", sans-serif',
        paddingTop: '1.5rem',        
    }
};

